<template>
  <div>
    <work-view-detail :result="result"/>
    <work-view-date-of-work :result="result"/>
    <work-view-contractor :result="result"/>
    <work-view-jsa :work-id="result.workId" :card-collapsed="true"/>
    <work-view-activity :work-id="result.workId" :card-collapsed="true"/>

    <b-row>
      <b-col class="text-center">
        <b-button variant="success" class="my-1" :to="{name: 'pageWork'}">
          <feather-icon icon="ChevronLeftIcon"/>
          {{ $t('general.btnGoHome') }}
        </b-button>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton} from 'bootstrap-vue'
import WorkViewDetail from "@/components/work/WorkViewDetail"
import WorkViewDateOfWork from "@/components/work/WorkViewDateOfWork"
import WorkViewContractor from "@/components/work/WorkViewContractor"
import WorkViewJsa from "@/components/work/WorkViewJsa"
import WorkViewActivity from "@/components/work/WorkViewActivity"
import formMixins from "@/mixins/formMixins"

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    WorkViewDetail,
    WorkViewDateOfWork,
    WorkViewContractor,
    WorkViewJsa,
    WorkViewActivity,
  },
  mixins: [formMixins],
  async created() {
    this.isShowLoading = false
    this.result = this.$store.state.work.createSuccess
    this.enabledCheckBackButton()
  },
  beforeDestroy() {
    this.$store.commit('work/setCreateSuccess', {})
    this.$store.commit('work/setStep1', {})
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    result: {},
  }),
  methods: {}
}
</script>
